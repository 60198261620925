// Bourbon mixins and Neat grid

@import "libs/bourbon/bourbon";
@import "libs/neat/neat";

// Normalize.css

@import "libs/normalize";

// Plugin CSS



// Box-sizing reset

* {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

/*
 * Mixins
 */

@import "libs/flexbox";

// REM mixin

$baseline-px: 10px;

@mixin rem($property, $px-values) {
  // Convert the baseline into rems
  $baseline-rem: $baseline-px / 1rem * 1;
  // Print the first line in pixel values
  #{$property}: $px-values;
  // If there is only one (numeric) value, return the property/value line for it.
  @if type-of($px-values) == "number" {
    #{$property}: $px-values / $baseline-rem; }
  @else {
    // Create an empty list that we can dump values into
    $rem-values: ();
    @each $value in $px-values {
      // If the value is zero or not a number, return it
      @if $value == 0 or type-of( $value ) != "number" {
        $rem-values: append($rem-values, $value); }
      @else {
        $rem-values: append($rem-values, $value / $baseline-rem); } }
    // Return the property and its list of converted values
    #{$property}: $rem-values; } }

.skiplink {
	position: absolute;
	left: -9999em;
	display: block;
}

// Helper classes

/*
 * Hide from both screenreaders and browsers: h5bp.com/u
 */

.hidden {
    display: none !important;
    visibility: hidden;
}

/*
 * Hide only visually, but have it available for screenreaders: h5bp.com/v
 */

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

/*
 * Extends the .visuallyhidden class to allow the element to be focusable
 * when navigated to via the keyboard: h5bp.com/p
 */

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

/*
 * Hide visually and from screenreaders, but maintain layout
 */

.invisible {
    visibility: hidden;
}

// unstyled list

.unstyled-list {
	padding: 0;
	margin: 0;
}

.unstyled-list li {
	list-style-type: none;
}

// inline list

.inline-list {
	@include clearfix;
}

.inline-list li {
	float: left;
}

// text truncate

.text-truncate {
    max-width: 100%; /* 1 */
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    word-wrap: normal !important; /* 2 */
}