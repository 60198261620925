@import '../../global';
// border colors

$gemma-border-light: #e2e8ec; // 95% #eee on king blue
$gemma-border-dark: #d2d8dc; // 95% #ddd on king blue

$gemma-highlight: #f7fbfd; // 97% #fff on king blue

// Gemma ui font size resets

.gemma-catalog {

    body {
        overflow-y: scroll;
    }

	// ui-module

	.ui-module {

		h1,
		h2,
		h3 {
			@include rem('font-size', 24px);
			line-height: 1.1666666666666667em;
		}

		h4,
		h5,
		h6 {
			@include rem('font-size', 18px);
			@include rem('line-height', 22px);
		}

		p,
		label,
		select,
		input,
		table,
		ul,
		ol,
		button {
			@include rem('font-size', 14px);
			@include rem('line-height', 18px);
		}

		button,
		input[type="submit"] {
			@include rem('padding', 3px 6px);
		}

	}

	.password-strength {
		@include rem('font-size', 14px);
		@include rem('line-height', 18px);
	}

	.ui-module .ui-module .ui-module-header > *,
	.ui-list .ui-module-header > * {
		@include rem('font-size', 16px);
	}

	// ui buttons

	.ui-btn {
		@include rem('font-size', 14px);

		&.ui-btn-tiny {
			@include rem('font-size', 12px);
			padding: 3px 6px;
			border-radius: 2px;
			line-height: 1.5;
		}

	}
  // forms
  label {
    font-weight: bold;
  }
  .form-checkboxes {
    label {
      font-weight: normal;
    }
  }
  .form-item {
    small {
      @include rem('font-size', 14px);
    }
  }
  .form-item-added-norms {
    margin-top: 1.5em;
  }
  .container-inline-date {
    small {
      float: left;
      clear: both;
    }
    .date-padding {
      padding: 0;
    }
  }
 .ui-module-content {
   .field-type-text-long {
     width: 50%;
   }
 }

	.form-checkboxes .form-item input,
	.form-checkboxes .form-item label {
		@include inline-block;
		vertical-align: middle;
	}
}

.versionlist {
	margin-top: 0;
	padding: 0;
}

.versionlist li {
	@include rem('padding', 8px 0);
	list-style-type: none;
}

.ui-datepicker {
	width: 240px !important;
}

#modal-content {
	width: inherit !important;
}

#modal-content p {
	@include rem('font-size', 14px);
}

#modal-content .form-item {

	legend {
		@include rem('font-size', 16px);
	}

	input,
	small,
	select {
		@include rem('font-size', 14px);
	}

	label {
		@include rem('font-size', 16px);
	}

}

.ajax-progress {
  display: fixed;
  left: 200px;
  bottom: 200px;
}