@import "global";
@import "inc/colors";
@import "inc/gemma/gemma-settings";


.title-with-info {
  white-space: nowrap;
  .title-with-tooltip {
    display: inline-block;
    float: left;
    margin-right: 5px;
    max-width: calc(100% - 50px);
  }
}


a.info-link {
  position: relative;
  outline: none;
  &:hover {
    &::after {
      content: attr(data-tooltip);
      position: absolute;
      top: 20px;
      display: block;
      white-space: pre-wrap;
      background: $color-secondary-blue;
      padding: 8px;
      font-size: 14px;
      color: #000;
      box-shadow: 5px 5px 8px #cccccc;
      border: 1px solid $gemma-border-dark;
      z-index: 10;
      width: 400px;
    }
  }
}

.icon {
  font-family: "FontAwesome";
  display: inline-block;
  font-style: normal;
  color: #888888;
  margin: 0 0.25em;
  &:hover {
    color: $color-primary-blue;
  }
}

.icon-info::before {
  content: "\f059";
}

.icon-link::before {
  content: "\f08e";
  cursor: pointer;
}


.info-trigger {
  text-decoration: none;
  cursor: pointer;
  &:before {
    @include rem('font-size', 20px);
  }
  @include rem('margin-left', 20px);
}

.form-header.tool-tip {
  h2 {
    display: inline-block;
  }
  .info-element {
    display: none; //start hidden.
  }
}